<template>
  <BaseLayout title="自动建仓">
    
    <van-popup v-model:show="state.showPicker1" round position="bottom">
      <van-picker title="选择推荐方案" :columns="state.list1" :columns-field-names="{text:'name'}" @cancel="state.showPicker1 = false" @confirm="onConfirm1" />
    </van-popup>
    
    <van-popup v-model:show="state.showPicker2" round position="bottom">
      <van-picker title="选择策略模板" :columns="state.list2" :columns-field-names="{text:'name'}" @cancel="state.showPicker2 = false" @confirm="onConfirm2" />
    </van-popup>

    <van-form class="form">

      <van-cell class="chooser" :class="state.list1.length > 0 ? 'animate__animated animate__flash' : ''" :border="false" :title="title1" :label="'共有 ' + state.list1.length + ' 个推荐方案'" is-link @click="state.showPicker1 = true" />
      
      <van-cell class="chooser" :class="state.list2.length > 0 ? 'animate__animated animate__flash' : ''" :border="false" :title="title2" :label="'共有 ' + state.list2.length + ' 个策略模板'" is-link @click="state.showPicker2 = true" />

      <fieldset class="fields" v-show="state.sid > 0">
        
        <legend>所选方案参数设置</legend>

        <van-field class="stepper" label="限制最大任务数" label-width="200" right-icon="warning-o" @click-right-icon="showTip('该方案允许同时进行的最大监控任务数量（持仓币种数量）')">
          <template #input><van-stepper v-model="state.config.limit_task" step="1" min="1" max="100" button-size="18" /></template>
        </van-field>

        <van-field class="stepper" label="限制补仓率（补仓/建仓）" label-width="200" right-icon="warning-o" @click-right-icon="showTip('待推荐币种的历史补仓次数与建仓次数的比值 < 设置比值时推荐（根据整个系统历史成交数据统计）')">
          <template #input><van-stepper v-model="state.config.limit_rate" step="0.1" min="0.1" max="10" button-size="18" /></template>
        </van-field>

        <van-field class="stepper" label="相同币种推荐间隔（分钟）" label-width="200" right-icon="warning-o" @click-right-icon="showTip('同一方案下同一币种推荐的最小时间间隔')">
          <template #input><van-stepper v-model="state.config.limit_interval" step="1" min="3" max="1440" button-size="18" /></template>
        </van-field>

        <van-field class="stepper" label="相同币种的监控任务数" label-width="200" right-icon="warning-o" @click-right-icon="showTip('同一方案下同一币种允许的最大监控（持仓）任务数量')">
          <template #input><van-stepper v-model="state.config.limit_number" step="1" min="1" max="10" button-size="18" /></template>
        </van-field>

        <van-field class="stepper" label="限制买入价1（买入/上次买入）" label-width="200" right-icon="warning-o" @click-right-icon="showTip('同一方案下待推荐币种的买入价和正在持仓的同一币种的买入价格的比值 < 设置的比值时推荐；若无持仓则忽略此规则')">
          <template #input><van-stepper v-model="state.config.limit_price1" step="0.01" min="0.01" max="10" button-size="18" /></template>
        </van-field>

        <van-field class="stepper" label="限制买入价2（买入/上次卖出）" label-width="200" right-icon="warning-o" @click-right-icon="showTip('同一方案下待推荐币种的买入价与该币种最近60分钟内卖出价的比值 < 设置比值时推荐，最近60分钟内没卖出则忽略此规则')">
          <template #input><van-stepper v-model="state.config.limit_price2" step="0.01" min="0.01" max="10" button-size="18" /></template>
        </van-field>

      </fieldset>
      
      <van-button block class="submit" type="primary" @click="onSubmit">保存</van-button>

      <div class="notice">温馨提示：BTC 价格暴跌则立即暂停自动建仓30分钟；如果系统内置的自动建仓推荐方案不能满足您的要求，请升级钻石会员后按照自己的指标要求进行自定义。</div>

    </van-form>

  </BaseLayout>
</template>

<style scoped>
.form {
  margin: 10px;
  border-radius: 5px;
}
.chooser {
  margin-top: 10px;
  background: #F6F6F6;
  border-radius: 5px;
  border: solid 1px #DDD;
  color: #000;
}
.fields {
  margin: 15px 0;
  padding: 0;
  background: #FFF;
  border-radius: 5px;
  border: solid 1px #DDD;
  overflow: hidden;
}
legend {
  margin-left: 10px;
  padding: 5px;
  background: #F6F6F6;
  border-radius: 5px;
  border: solid 1px #DDD;
  font-size: 14px;
  text-align: left;
}
.stepper {
  padding: 10px 5px 10px 15px;
}
.submit {
  margin:10px 0;
}

.notice {
  margin-top: 20px;
  padding: 10px;
  color: #999;
  font-size: 12px;
  line-height: 24px;
}
</style>

<script>
import {reactive} from 'vue'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

setup(){
    const state = reactive({
      showPicker1: false,
      showPicker2: false,
      list: [], // 自有计划
      list1: [{id:1, name: "快线：低位急速下跌时买入"}, {id: 2, name: "短线：低位上涨趋势时买入"}],     // 所有方案
      list2: [], // 自有策略
      pid: 0,
      sid: 0,
      config: {
        limit_task: 10,
        limit_rate: 2,
        limit_interval: 3,
        limit_number: 2,
        limit_price1: 0.9,
        limit_price2: 1.1,
      },
    });

    return { state };
  },

  created() {
    this.initPlan();
    this.$http.post("/exchange/strategylist").then((data) => {
      if (data.length == 0){
        this.$toast("还没有策略模板，请先增加");
        return this.$router.push("/my/strategy/create");
      }
      this.state.list2 = [{id: 0, name: "【不使用选择的方案】"}].concat(data);
    });
  },

  computed: {
    name1(){
      let name = "";
      for (let i in this.state.list1){
        if (this.state.list1[i].id == this.state.pid){
          name = this.state.list1[i].name;
          break;
        }
      }
      return name;
    },
    name2(){
      let name = "";
      for (let i in this.state.list2){
        if (this.state.list2[i].id == this.state.sid){
          name = this.state.list2[i].name;
          break;
        }
      }
      return name;
    },
    title1(){
      return this.state.pid == 0 ? "请选择系统内置的自动建仓推荐方案" : "符合【 " + this.name1 + " 】方案时";
    },
    title2(){
      if (this.state.pid == 0){
        return "请先选择推荐方案，再选择量化策略";
      }
      return this.state.sid == 0 ? "【暂停推荐】" : "使用【 " + this.name2 + " 】策略逢低建仓";
    },
  },

  methods: {
    initPlan(){
      this.$http.get("/exchange/planlist").then((data) => this.state.list = data);
    },
    showTip(msg){
      this.$toast({message:msg, duration:0, closeOnClick:true});
    },
    onConfirm1(obj){
      this.state.showPicker1 = false;
      this.state.pid = obj.id;
      if (this.state.list.length == 0) return;
      for (let i in this.state.list){
        if (this.state.list[i].pid == this.state.pid){
          this.state.sid = this.state.list[i].sid;
          this.state.config = {...this.state.config, ...JSON.parse(this.state.list[i].config)};
          break;
        }
      }
    },
    onConfirm2(obj){
      this.state.showPicker2 = false;
      if (this.state.pid == 0){
        this.state.sid = 0;
        return this.$toast("请先选择方案");
      }
      this.state.sid = obj.id;
      this.state.name2 = obj.name;
    },
    onSubmit(){
      if (this.state.pid == 0)
        return this.$toast("请先选择方案");
      
      this.$http.post("/exchange/plansave", {pid:this.state.pid, sid:this.state.sid, config:JSON.stringify(this.state.config)}).then(() => {
        this.initPlan();
      });
    },
  }

}
</script>
